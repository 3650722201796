.App {
  text-align: center;
}

img {
  max-width: 100%;
}

.glider-img {
  width: 680px;
  max-width: 100%;
}

iframe {
  max-width: 100%;
}